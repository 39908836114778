const t = {
    icon: 'fa-building',
    menus: {
        main: {section: 'customers', priority: 50},
    },
    new: {
        attributes: [
            'campaign', 'country', 'email', 'firstName', 'game', 'lastName', 'locale', 'name',
            'organization', 'partner', 'phone',
        ],
        content: [
            ['row', [
                ['block', [
                    '$name!', '$email', '$firstName', '$lastName', '$phone', '$country', '$locale',
                ]],
                ['block', [
                    '$partner!', '$campaign', '$organization', '$partner',
                ]],
            ]],
            '$submit',
        ]
    },
    edit: {
        attributes: [
            'campaign', 'country', 'email', 'firstName', 'game', 'lastName', 'locale', 'name',
            'organization', 'partner', 'phone',
        ],
        content: [
            ['row', [
                ['block', [
                    '$name!', '$email', '$firstName', '$lastName', '$phone', '$country', '$locale',
                ]],
                ['block', [
                    '$partner!', '$campaign', '$organization', '$partner',
                ]],
            ]],
            '$submit',
        ]
    },
    display: {
        tabs: [
            {name: 'infos'},
            {name: 'steps'},
        ]
    },
    list: {
        columns: [
            {id: 'requestedLogoImage', label: 'column_logoimage_label', flex: 0, width: 110, render: 'image'} as any,
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 150} as any,
            {id: 'age', value: 'createdAt', label: 'column_age_label', component: 'registration_age', flex: 0, width: 100} as any,
            {id: 'status', label: 'column_status_label', flex: 0, width: 120} as any,
            {id: 'email', label: 'column_email_label', flex: 0, width: 340} as any,
            {id: 'phone', label: 'column_phone_label', flex: 0, width: 120} as any,
            {id: 'requestedOrganizationName', label: 'column_organization_label', flex: 0, width: 320} as any,
            {id: 'requestedGameName', label: 'column_game_label', flex: 0, width: 320} as any,
            {id: 'game', label: 'column_game_label', flex: 0, width: 360, component: 'game'} as any,
            {id: 'partner', label: 'column_partner_label', flex: 0, width: 120, component: 'partner'} as any,
            {id: 'utmSource', label: 'column_utmsource_label', flex: 0, width: 100} as any,
            {id: 'lastStep', label: 'column_laststep_label', flex: 0, width: 140} as any,
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete', 'go_private_page'],
        filters: {
            notCompleted: {},
            completed: {},
            completedGame: {},
            completedSponsor: {},
            completedDonee: {},
            canceled: {},
            all: {},
        }
    },
};

export default t;